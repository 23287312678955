<template>
  <AppViewport
    class="App"
    background="#000"
    :viewport-background="theme.background"
    :transition-name="transitionName">
    <router-view class="view" />
  </AppViewport>
</template>

<script>
import store from '@/store';
import localsPreprocess from '@/locales-preprocess';

import AppViewport from '@/modules/memogenius-game/components/AppViewport';

// import coverImage from './images/cover.jpg'
// import appImage from './images/app-icon.png'
import sharedMessages from '@/modules/memogenius-game/i18n';
import wrapVuexModule from '@/modules/games-shared/wrapVuexModule';

import appMessages from './i18n';

// import router from './router'

const vuexModule = wrapVuexModule(store, 'moduleSystemName');

const theme = {
    background: '#1D1D1D', // 'linear-gradient(#452f94, #23226a)',
    levelItem: {
        gradient: '#231363',
        gradientActive: 'linear-gradient(#9c46b3, #b467c9)'
    },
    rankingRow: {
        colorActive: '#b569ca'
    },
    primaryColor: '#7ED321' // '#D578DE',
    // coverImage: coverImage
    // appImage: appImage
};

export default {
    name: 'SystemName',
    components: { AppViewport },
    provide() {
        return {
            module: vuexModule,
            theme: this.theme
        };
    },
    data() {
        return {
            // routeConf: null,
            transitionName: ''
        };
    },
    watch: {
        // currentPath: {
        //   immediate: true,
        //   handler (newVal, oldVal) {
        //     const { resolved } = router.resolve(this.currentPath)
        //     const conf = resolved.matched[0]
        //     this.routeConf = {
        //       component: conf.components.default,
        //       name: conf.name,
        //       props: conf.props.default,
        //       meta: conf.meta
        //     }
        //   }
        // },
        // routeConf (newVal, oldVal) {
        //   const fromHint = oldVal.meta.transitionHint || 'none'
        //   const toHint = newVal.meta.transitionHint || 'none'
        //   this.transitionName = `${fromHint}_to_${toHint}`
        // }
    },
    // computed: {
    //   currentPath () {
    //     return vuexModule.getters('currentPath')
    //   }
    // },
    beforeCreate() {
        this.theme = theme;
    },
    created() {
        this.dbgModule = vuexModule;

        this.$i18n.mergeLocaleMessage('no', {
            'memogenius-shared': localsPreprocess(sharedMessages).no,
            currentGame: localsPreprocess(appMessages).no
        });
        vuexModule.dispatch('load');
    },
    mounted() {},
    beforeDestroy() {
        vuexModule.dispatch('unload');
    }
};
</script>
